import axios from 'axios'
import { getLocalStorageToken } from 'src/utils/common'
import { toast } from 'react-toastify'

const baseURL = process.env.REACT_APP_APIURL
// const ForntURL = process.env.REACT_APP_Front_APIURL

// const confg = async (data) => {
//   return await axios({
//     method: data?.method,
//     url: baseURL + data?.url,
//     headers: data?.headers,
//     params: data?.params,
//     data: data?.data,
//   })
// }

const errorMessagesDisplayed = new Set()

const confg = async (data) => {
  try {
    const response = await axios({
      method: data?.method,
      url: baseURL + data?.url,
      headers: data?.headers,
      params: data?.params,
      data: data?.data,
    })
    return response
  } catch (error) {
    // console.log("main Api =", error);

    // Check if the error message has already been displayed
    const errorMessage = error?.response?.data?.message || 'something went wrong'
    if (!errorMessagesDisplayed.has(errorMessage)) {
      errorMessagesDisplayed.add(errorMessage)
      if (
        error?.response?.data?.message &&
        (typeof error?.response?.data?.message == 'object' ||
          Array.isArray(error?.response?.data?.message))
      ) {
        for (const property in error?.response?.data?.message) {
          toast.error(`${error?.response?.data?.message[property]}`)
        }
      } else {
        toast.error(errorMessage)
      }
    }

    if (error?.response?.status === 401) {
      setTimeout(() => {
        localStorage.removeItem('Login')
        window?.location?.replace('/')
      }, 2000)
    } else {
      errorMessagesDisplayed.clear()
    }

    throw new Error(error)
  }
}

export const LoginApi = (data) => {
  return axios({
    method: 'post',
    url: `${baseURL}auth/admin-login`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

export const ForgotPassowrdApi = (data) => {
  return axios ({
    method: 'POST',
    url: `${baseURL}auth/forgot-password`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

export const ResetPassowrdApi = (data) => {
  return axios ({
    method: 'POST',
    url: `${baseURL}auth/reset-password`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

// Dashboard API
export const DashboardUserGraphApi = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    // url: 'dashboard/get-activity?yesr='+year,
    url: 'analytics/admin/user-graph-data',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const DashboardUserNewGraphApi = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    // url: 'dashboard/get-activity?yesr='+year,
    url: 'analytics/admin/user-graph-data-new',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const DashboardEventGraphApi = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    // url: 'dashboard/get-activity?yesr='+year,
    url: 'analytics/admin/event-graph-data',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const DashboardEventNewGraphApi = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    // url: 'dashboard/get-activity?yesr='+year,
    url: 'analytics/admin/event-graph-data-new',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const DashboardCountApi = async () => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'analytics/admin/user-analytics',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

// Change Password Api
export const ChangePasswordApi = async (data) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'post',
    url: 'auth/admin/change-password',
    headers: {
      Authorization: 'Bearer ' + token,
      'content-type': 'application/json',
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

// User Api
export const UserAllData = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'auth/admin/get-users',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserStatusData = async (id, data) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'PUT',
    url: `auth/admin/update-users/${id}`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const UserDataById = async (id) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: `auth/admin/user/${id}`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserAppoitmentJoined = async (id) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: `auth/admin/appointment-invite/${id}`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserAppoitmentCreated = async (id) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: `auth/admin/appointments/${id}`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserGroupCalender = async (id) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: `calendar/admin/user-calendar/${id}`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserGroupCalenderInvite = async (id) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: `calendar/admin/calendar-invite/${id}`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const SendEmail = async (data) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'POST',
    url: 'auth/admin/send-email',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const GetALLNotificationApi = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'notification/admin',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

// Appoitment Api
export const AppoitmentAllData = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'auth/admin/appointments',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }
  let Token = await confg(config)
  return Token
}

// Version Api
export const VersionGetApi = async () => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'auth/app-version',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const VersionUpdateApi = async (data) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'PUT',
    url: 'auth/app-version',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

// App Store Api

export const DeleteAppStoreApi = async (data) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'POST',
    url: 'auth/admin/delete-request',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const VerifyOTPAppStoreApi = async (data) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'POST',
    url: 'auth/admin/delete-user-verfication',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const GetAppStoreApi = async (data) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'auth/admin/delete-request',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data,
  }

  let Token = await confg(config)
  return Token
}



// Athes API

// App User Api
export const UsersAdd = async (data) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'post',
    url: 'users/create',
    headers: {
      Authorization: 'Bearer ' + token,
      'content-type': 'application/json',
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const UserUpdateData = async (data) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'put',
    url: 'users/update',
    headers: {
      Authorization: 'Bearer ' + token,
      'content-type': 'multipart/form-data',
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const TrainerAllData = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/get-trainer',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserOnlyAllData = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/get-users',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserDataDrillReport = async (id, { params }) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/my-reported-drills/' + id,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }
  let Token = await confg(config)
  return Token
}

export const UserDataMembershipReport = async (id, { params }) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/my-reported-membership/' + id,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }
  let Token = await confg(config)
  return Token
}

export const TrainnerDataMembershipCreatedByMeReport = async (id, { params }) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/my-membership/' + id,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }
  let Token = await confg(config)
  return Token
}

export const UserDataBlockUserReport = async (id, { params }) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/block-by-user/' + id,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }
  let Token = await confg(config)
  return Token
}

export const BlockReportByMeApi = async (id, { params }) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/block-by-me/' + id,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }
  let Token = await confg(config)
  return Token
}

export const GuestUserData = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/guest-get',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }
  let Token = await confg(config)
  return Token
}

export const UserReportData = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'feedback/list',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserArchiveData = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/get-archive-user',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserRestoreApi = async (id) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/restore-user/' + id,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserBlockApi = async (id) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/block/' + id,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserDeleteApi = async (id) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/delete/' + id,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserUnblockApi = async (id) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'users/unblock/' + id,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const UserSendMail = async (data) => {
  var token = getLocalStorageToken('Login')
  // return axios({
  //   method: 'post',
  //   url: baseURL + 'users/send-mail',
  //   'headers': {
  //     'Authorization': 'Bearer ' + Token,
  //     "content-type":"multipart/form-data"
  //   },
  //   data
  // })
  let config = {
    method: 'post',
    url: 'users/send-mail',
    headers: {
      Authorization: 'Bearer ' + token,
      'content-type': 'multipart/form-data',
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const InviteUserMail = async (data) => {
  var token = getLocalStorageToken('Login')
  // return axios({
  //   method: 'post',
  //   url: baseURL + 'users/invite-mail',
  //   'headers': {
  //     'Authorization': 'Bearer ' + Token
  //   },
  //   data
  // })
  let config = {
    method: 'post',
    url: 'users/invite-mail',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

// Sports Api
export const SportsAll = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'interest',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const SportsAdd = async (data) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'post',
    url: 'interest/create',
    headers: {
      Authorization: 'Bearer ' + token,
      'content-type': 'multipart/form-data',
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const SportsUpdateData = async (data) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'put',
    url: 'interest/update',
    headers: {
      Authorization: 'Bearer ' + token,
      'content-type': 'multipart/form-data',
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const SportsUpdateCategory = async (data) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'put',
    url: 'interest/enable-disable',
    headers: {
      Authorization: 'Bearer ' + token,
      // "content-type":"multipart/form-data"
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const AthesClassUpdateCategory = async (data) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'put',
    url: 'interest/athes-class-enable-disable',
    headers: {
      Authorization: 'Bearer ' + token,
      // "content-type":"multipart/form-data"
    },
    data,
  }

  let Token = await confg(config)
  return Token
}

export const SportsEnableDisable = async (id) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'interest/enable-disable/' + id,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }
  let Token = await confg(config)
  return Token
}

// Image Upload URL
export const UploadImageApi = async (type, Image) => {
  let urls = await axios({
    method: 'get',
    // url: ForntURL+'upload/sign?ext=' + type
  })
  // console.log('APIS = ',urls.data.data[0])
  let Img = urls.data?.data?.[0]
  const [ImageUrl, rests] = Img.split('?')
  let check = await UploadImageURLApi(Img, Image)
  // console.log('ckeck = ', check)
  if (check.statusText == 'OK' && check.status == 200) {
    return ImageUrl
  } else {
    return false
  }
}

export const UploadImageURLApi = async (URLS, data) => {
  // console.log("here = ",URLS)
  return await axios({
    method: 'put',
    url: URLS,
    data: data,
  })
}

// Web Page Api Without Auth
export const AboutUsApi = async (data) => {
  return axios({
    method: 'get',
    // url: baseURL + 'pages/get-page/about-us',
    url: baseURL + 'pages/get-page-list',
  })
}

export const VersionNotificationApi = (data) => {
  return axios({
    method: 'post',
    // url: ForntURL + 'send-version-update-notification',
    data: data,
  })
}

export const CommisionGetAllApi = async () => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'commision/get-list',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}
// Transaction Api
export const TransactionAll = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'transaction/get-list',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const TransactionById = async (id) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: `transaction/get-one/${id}`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  let Token = await confg(config)
  return Token
}

export const WithdrawalAll = async ({ params }) => {
  let token = getLocalStorageToken('Login')
  let config = {
    method: 'GET',
    url: 'withdrawal/get-list',
    headers: {
      Authorization: 'Bearer ' + token,
    },
    params: {
      ...params,
    },
  }

  let Token = await confg(config)
  return Token
}

export const CommisionUpdateData = async (data) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'put',
    url: 'commision/update',
    headers: {
      Authorization: 'Bearer ' + token,
      'content-type': 'multipart/form-data',
    },
    data,
  }
  let Token = await confg(config)
  return Token
}

//Notification Api
export const NotificationApi = async (data) => {
  var token = getLocalStorageToken('Login')
  let config = {
    method: 'post',
    url: 'send-notification',
    headers: {
      Authorization: 'Bearer ' + token,
      'content-type': 'multipart/form-data',
    },
    data,
  }

  let Token = await confg(config)
  return Token
}
